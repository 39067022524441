import { useRoutes, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import Layout from "./Layout/Layout";
import LoginPage from "./Pages/LoginPage/LoginPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
// import ProjectWiseAttendance from "./Pages/ReportPage/ProjectWiseAttendance";
import GrantLoanPage from "./Pages/Loan/GrantLoanPage";
import LoanReportPage from "./Pages/Loan/LoanReportPage";
import AddCandidatePage from "./Pages/Recruitment/AddCandidatePage";
import ManageCandidate from "./Pages/Recruitment/ManageCandidate";
import CandidateShortlist from "./Pages/Recruitment/CandidateShortlist";
import InterviewPage from "./Pages/Recruitment/InterviewPage";
import CandidateSelectionPage from "./Pages/Recruitment/CandidateSelectionPage";
import AccountSettingsPage from "./Pages/AccountSettings/AccountSettings";
import AuthGuard from "./components/authGuard/authGuard";
import UnauthorizedPage from "./Pages/UnauthorizedPage";
import LoadingPage from "./Pages/LoadingPage/LoadingPage";
import ForgotPassword from "./Pages/LoginPage/ForgotPassword";
import OTPInput from "./Pages/LoginPage/OTPInput";
import EmployeeAddPageNew from "./Pages/Employees/EmployeeAddPageNew.jsx";

//***** Dashboard Routes *****//
const LayoutHome = lazy(() => import("./Pages/HomePage/LayoutHome"));

//***** Client Routes *****//
const ClientsAddPage = lazy(() => import("./Pages/Clients/ClientsAddPage"));
const ClientsManagePage = lazy(() =>
  import("./Pages/Clients/ClientsManagePage")
);
const ClientsEditPage = lazy(() => import("./Pages/Clients/ClientsEditPage"));
const ClientDetailsPage = lazy(() =>
  import("./Pages/Clients/ClientDetailsPage")
);

//***** Project Routes *****//
const ProjectsAddPage = lazy(() => import("./Pages/Projects/ProjectsAddPage"));
const ProjectsManagePage = lazy(() =>
  import("./Pages/Projects/ProjectsManagePage")
);
const ProjectsEditPage = lazy(() =>
  import("./Pages/Projects/ProjectsEditPage")
);
const ProjectDetailsPage = lazy(() =>
  import("./Pages/Projects/ProjectDetailsPage")
);

//***** PO Routes *****//
const AddPOPage = lazy(() => import("./Pages/Projects/AddPOPage"));
const POManagePage = lazy(() => import("./Pages/Projects/POManagePage"));
const UpdatePOPage = lazy(() => import("./Pages/Projects/UpdatePOPage"));
const PODetailsPage = lazy(() => import("./Pages/Projects/PODetailsPage"));

//***** Agreement Routes *****//
const AddAgreementPage = lazy(() =>
  import("./Pages/Projects/AddAgreementPage")
);
const AgreementManagePage = lazy(() =>
  import("./Pages/Projects/AgreementManagePage")
);
const UpdateAgreementPage = lazy(() =>
  import("./Pages/Projects/UpdateAgreementPage")
);

//***** Department Routes *****//
const DepartmentAddPage = lazy(() =>
  import("./Pages/Department/DepartmentAddPage")
);
const DepartmentManagePage = lazy(() =>
  import("./Pages/Department/DepartmentManagePage")
);
const DepartmentEditPage = lazy(() =>
  import("./Pages/Department/DepartmentEditPage")
);

//***** Designation Routes *****//
const SubDepartmentAddPage = lazy(() =>
  import("./Pages/Department/DesignationAddPage")
);
const DesignationEditPage = lazy(() =>
  import("./Pages/Department/DesignationEditPage")
);
const DesignationManagePage = lazy(() =>
  import("./Pages/Department/DesignationManagePage")
);

//***** Employee Routes *****//
const EmployeeManagePage = lazy(() =>
  import("./Pages/Employees/EmployeeManagePage")
);
const EmployeeAddPage = lazy(() => import("./Pages/Employees/EmployeeAddPage"));
const EmployeeServiceBook = lazy(() =>
  import("./Pages/Employees/EmployeeServiceBook")
);
const EmployeeView = lazy(() => import("./Pages/Employees/EmployeeView"));

//***** Attendance Routes *****//
const ManageAttendancePage = lazy(() =>
  import("./Pages/Attendance/ManageAttendancePage")
);
const ManageBulkAttendance = lazy(() =>
  import("./Pages/Attendance/ManageBulkAttendance")
);
const OfficeSchedulePage = lazy(() =>
  import("./Pages/Attendance/OfficeSchedulePage")
);

const SelfAttendanceList = lazy(() =>
  import("./Pages/Attendance/SelfAttendanceList")
);
const AttendanceList = lazy(() => import("./Pages/Attendance/AttendanceList"));
const AttendanceView = lazy(() => import("./Pages/Attendance/AttendanceView"));
const AttendanceAdd = lazy(() => import("./Pages/Attendance/AttendanceAdd"));
const SummaryList = lazy(() => import("./Pages/Attendance/SummaryList"));
const MySummaryList = lazy(() => import("./Pages/Attendance/MySummaryList"));

//***** Leave Routes *****//
const WeeklyHolidayPage = lazy(() =>
  import("./Pages/Leaves/WeeklyHolidayPage")
);
const MyLeaveManage = lazy(() => import("./Pages/Leaves/MyLeaveManage"));
const HolidaysPage = lazy(() => import("./Pages/Leaves/HolidaysPage"));
const AddLeaveHoliday = lazy(() => import("./Pages/Leaves/AddLeaveHoliday"));
const EditLeaveHoliday = lazy(() => import("./Pages/Leaves/EditLeaveHoliday"));
const ViewLeave = lazy(() => import("./Pages/Leaves/ViewLeave"));

//***** Message Routes *****//
const MessageSend = lazy(() => import("./Pages/Message/MessageSend"));
const MyMessage = lazy(() => import("./Pages/Message/MyMessage"));
const Inbox = lazy(() => import("./Pages/Message/Inbox"));
const ComposeList = lazy(() => import("./Pages/Message/ComposeList"));
const MessageCompose = lazy(() => import("./Pages/Message/MessageCompose"));
const ViewMessage = lazy(() => import("./Pages/Message/ViewMessage"));

//***** Bank Routes *****//
const AddBankAccountPage = lazy(() =>
  import("./Pages/Bank/AddBankAccountPage")
);
const BankListPage = lazy(() => import("./Pages/Bank/BankListPage"));
const EditBankAccountPage = lazy(() =>
  import("./Pages/Bank/EditBankAccountPage")
);

//***** Award Routes *****//
const AwardList = lazy(() => import("./Pages/Award/AwardList"));
const AddAward = lazy(() => import("./Pages/Award/AddAward"));
const EditAward = lazy(() => import("./Pages/Award/EditAward"));
const AwardView = lazy(() => import("./Pages/Award/AwardView"));

//***** Announcement Routes *****//
const AnnouncementList = lazy(() =>
  import("./Pages/Announcement/AnnouncementList")
);
const MyAnnouncementList = lazy(() =>
  import("./Pages/Announcement/MyAnnouncementList")
);
const AnnouncementView = lazy(() =>
  import("./Pages/Announcement/AnnouncementView")
);

//***** Holiday Routes *****//
const HolidayList = lazy(() => import("./Pages/Holiday/HolidayList"));
const HolidayView = lazy(() => import("./Pages/Holiday/HolidayView"));

//***** Transfer Routes *****//
const TransferList = lazy(() => import("./Pages/Transfer/TransferList"));
const MyTransferList = lazy(() => import("./Pages/Transfer/MyTransferList"));
const TransferView = lazy(() => import("./Pages/Transfer/TransferView"));

//***** Tax Chalan Routes *****//
const SalaryChartNew = lazy(() => import("./Pages/Payroll/SalaryChartNew"));
const SalaryChart = lazy(() => import("./Pages/Payroll/SalaryChart"));
const Report108PageNew = lazy(() =>
  import("./Pages/TaxChalan/Report108PageNew")
);
const Report108APageNew = lazy(() =>
  import("./Pages/TaxChalan/Report108APageNew")
);
const GenerateTaxChalanPageNew = lazy(() =>
  import("./Pages/TaxChalan/GenerateTaxChalanPageNew")
);
const TaxChallanListNew = lazy(() =>
  import("./Pages/TaxChalan/TaxChallanListNew")
);
const ChallanReport = lazy(() => import("./Pages/TaxChalan/ChallanReport"));
const ChallanView = lazy(() => import("./Pages/TaxChalan/ChallanView"));

//***** Payroll Routes *****//
const GenerateSalaryPage = lazy(() =>
  import("./Pages/Payroll/GenerateSalaryPage")
);
const ManagePayrollPage = lazy(() =>
  import("./Pages/Payroll/ManagePayrollPage")
);
const PayslipPage = lazy(() => import("./Pages/Payroll/Payslip"));
const PayslipBanglaPage = lazy(() => import("./Pages/Payroll/PayslipBangla"));
const EditGenerateSalaryEmployeeTable = lazy(() =>
  import("./Pages/Payroll/EditGenerateSalaryEmployeeTable")
);
const GenerateSalaryBulk = lazy(() =>
  import("./Pages/Payroll/GenerateSalaryBulk")
);
const GenerateSalaryEmployeeTable = lazy(() =>
  import("./Pages/Payroll/GenerateSalaryEmployeeTable")
);
const PayrollList = lazy(() => import("./Pages/Payroll/PayrollList"));
const ManagePayrolls = lazy(() => import("./Pages/Payroll/ManagePayrolls"));
const MyPayrollList = lazy(() => import("./Pages/Payroll/MyPayrollList"));
const PayrollView = lazy(() => import("./Pages/Payroll/PayrollView"));
const PayrollGenerate = lazy(() => import("./Pages/Payroll/PayrollGenerate"));
const AllGenerate = lazy(() => import("./Pages/Payroll/AllGenerate"));
const SingleEditPayrollGenerate = lazy(() =>
  import("./Pages/Payroll/SingleEditPayrollGenerate")
);

//***** Settlement Routes *****//
const SettlementListPage = lazy(() =>
  import("./Pages/Settlements/SettlementListPage")
);
const NewSettlementPage = lazy(() =>
  import("./Pages/Settlements/NewSettlementPage")
);
const SettlementPage = lazy(() => import("./Pages/Settlements/Settlement"));
const EditSettlementPage = lazy(() =>
  import("./Pages/Settlements/EditSettlementPage")
);
const SettlementView = lazy(() => import("./Pages/Settlements/SettlementView"));

//***** Report Routes *****//
const AttendanceReportPage = lazy(() =>
  import("./Pages/ReportPage/AttendanceReportPage")
);
const LeaveReportPage = lazy(() =>
  import("./Pages/ReportPage/LeaveReportPage")
);
const DailyAttendanceReport = lazy(() =>
  import("./Pages/ReportPage/DailyAttendanceReport")
);

const AttendanceListReportDaily = lazy(() =>
  import("./Pages/AttendanceReport/AttendanceList")
);
const AttendanceListReportSummery = lazy(() =>
  import("./Pages/AttendanceReport/SummaryList")
);
const AttendanceListReportView = lazy(() =>
  import("./Pages/AttendanceReport/AttendanceAdd")
);
const AttendanceListSummeryView = lazy(() =>
  import("./Pages/AttendanceReport/AttendanceView")
);
const EmployeeReportPage = lazy(() =>
  import("./Pages/ReportPage/EmployeeReportPage")
);
const LeaveReportPageTwo = lazy(() =>
  import("./Pages/LeavesReport/WeeklyHolidayPage")
);
const PayrollListReport = lazy(() =>
  import("./Pages/PayrollReport/PayrollList")
);
const SettlementListPageReport = lazy(() =>
  import("./Pages/SettlementsReport/SettlementListPage")
);

//***** User Routes *****//
const UserPermissionList = lazy(() =>
  import("./Pages/User/UserPermissionList")
);

//***** Device Routes *****//
const DeviceUserList = lazy(() =>
  import("./Pages/DeviceManage/DeviceUserList")
);

//***** Settings Routes *****//
const AddKAMPage = lazy(() => import("./Pages/Settings/AddKAMPage"));
const ManageKAMPage = lazy(() => import("./Pages/Settings/ManageKAMPage"));
const EditKAMPage = lazy(() => import("./Pages/Settings/EditKAMPage"));
const ManagePermission = lazy(() =>
  import("./Pages/Settings/ManagePermission")
);
const TaxSettingsPage = lazy(() => import("./Pages/Settings/TaxSettingsPage"));
const AddAdminPage = lazy(() => import("./Pages/Settings/AddAdminPage"));
const ManageAdminPage = lazy(() => import("./Pages/Settings/ManageAdminPage"));
const EditAdminPage = lazy(() => import("./Pages/Settings/EditAdminPage"));
const ManageChallanYear = lazy(() => import("./Pages/Settings/ManageTaxYear"));
const AppConfig = lazy(() => import("./Pages/AppConfig/AppConfig"));
const BankConfig = lazy(() => import("./Pages/AppConfig/BankConfig"));
const BranchList = lazy(() => import("./Pages/Branch/BranchList"));
const AddBranch = lazy(() => import("./Pages/Branch/AddBranch"));
const EditBranch = lazy(() => import("./Pages/Branch/EditBranch"));
const GradeList = lazy(() => import("./Pages/Grade/GradeList"));
const AddGrade = lazy(() => import("./Pages/Grade/AddGrade"));
const EditGrade = lazy(() => import("./Pages/Grade/EditGrade"));
const RoleList = lazy(() => import("./Pages/Role/RoleList"));
const AddRole = lazy(() => import("./Pages/Role/AddRole"));
const EditRole = lazy(() => import("./Pages/Role/EditRole"));
const AttendanceConfig = lazy(() =>
  import("./Pages/Attendance/AttendanceConfig")
);
const AwardTypeList = lazy(() => import("./Pages/Award-Type/AwardTypeList"));
const AddAwardType = lazy(() => import("./Pages/Award-Type/AddAwardType"));
const EditAwardType = lazy(() => import("./Pages/Award-Type/EditAwardType"));
const JobContactList = lazy(() => import("./Pages/Job-Contact/JobContactList"));
const AddJobContact = lazy(() => import("./Pages/Job-Contact/AddJobContact"));
const EditJobContact = lazy(() => import("./Pages/Job-Contact/EditJobContact"));
const LeaveTypeList = lazy(() => import("./Pages/Leave-Type/LeaveTypeList"));
const AddLeaveType = lazy(() => import("./Pages/Leave-Type/AddLeaveType"));
const EditLeaveType = lazy(() => import("./Pages/Leave-Type/EditLeaveType"));
const SalaryTypeList = lazy(() => import("./Pages/Salary-Type/SalaryTypeList"));
const AddSalaryType = lazy(() => import("./Pages/Salary-Type/AddSalaryType"));
const EditSalaryType = lazy(() => import("./Pages/Salary-Type/EditSalaryType"));
const CertificateTypeList = lazy(() =>
  import("./Pages/Certificate-Type/CertificateTypeList")
);
const AddCertificateType = lazy(() =>
  import("./Pages/Certificate-Type/AddCertificateType")
);
const EditCertificateType = lazy(() =>
  import("./Pages/Certificate-Type/EditCertificateType")
);

// Activity Route
const ActivityList = lazy(() => import("./Pages/Activity/ActivityList"));
const AddActivity = lazy(() => import("./Pages/Activity/AddActivity"));
const EditActivity = lazy(() => import("./Pages/Activity/EditActivity"));

// Activity Type Route
const ActivityTypeList = lazy(() =>
  import("./Pages/Activity-Type/ActivityTypeList")
);
const AddActivityType = lazy(() =>
  import("./Pages/Activity-Type/AddActivityType")
);
const EditActivityType = lazy(() =>
  import("./Pages/Activity-Type/EditActivityType")
);
const DocumentTypeList = lazy(() =>
  import("./Pages/Document-Type/DocumentTypeList")
);
const AddDocumentType = lazy(() =>
  import("./Pages/Document-Type/AddDocumentType")
);
const EditDocumentType = lazy(() =>
  import("./Pages/Document-Type/EditDocumentType")
);

//Department
const DepartmentList = lazy(() => import("./Pages/Departments/DepartmentList"));
const AddDepartment = lazy(() => import("./Pages/Departments/AddDepartment"));
const EditDepartment = lazy(() => import("./Pages/Departments/EditDepartment"));

//Designation
const DesignationList = lazy(() =>
  import("./Pages/Designation/DesignationList")
);
const AddDesignation = lazy(() => import("./Pages/Designation/AddDesignation"));
const EditDesignation = lazy(() =>
  import("./Pages/Designation/EditDesignation")
);

const OfficeShiftList = lazy(() =>
  import("./Pages/Office-Shift/OfficeShiftList")
);
const AddOfficeShift = lazy(() =>
  import("./Pages/Office-Shift/AddOfficeShift")
);
const EditOfficeShift = lazy(() =>
  import("./Pages/Office-Shift/EditOfficeShift")
);
const ViewOfficeShift = lazy(() =>
  import("./Pages/Office-Shift/ViewOfficeShift")
);
const ScriptAdd = lazy(() => import("./Pages/Script/ScriptAdd"));
const MailConfig = lazy(() => import("./Pages/Mail/MailConfig"));
const KeyList = lazy(() => import("./Pages/Key/KeyList"));
const AddKey = lazy(() => import("./Pages/Key/AddKey"));
const EditKey = lazy(() => import("./Pages/Key/EditKey"));
const LogoList = lazy(() => import("./Pages/Logo/LogoList"));
const RolePermission = lazy(() =>
  import("./Pages/Permission/RolePermission.jsx")
);
const PermissionChange = lazy(() =>
  import("./Pages/Permission/PermissionChange.jsx")
);
const PermissionUserChange = lazy(() =>
  import("./Pages/Permission/PermissionUserChange.jsx")
);
const UserPermission = lazy(() =>
  import("./Pages/Permission/UserPermission.jsx")
);

//***** Profile Routes *****//
const ProfilePage = lazy(() => import("./Pages/Profile/ProfilePage"));

const Router = () => {
  return useRoutes([
    {
      path: "/unauthorized",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <UnauthorizedPage />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard redirectRoute={"/login/"}>
          <Navigate to={"/dashboard/home"} />
        </AuthGuard>
      ),
    },
    {
      path: "/dashboard",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/dashboard/home"} />
            </AuthGuard>
          ),
        },
        {
          path: "home",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <LayoutHome />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/clients",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/clients/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ClientsManagePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "details/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ClientDetailsPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ClientsAddPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ClientsEditPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/employees",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/employees/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EmployeeManagePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EmployeeAddPageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add/new",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EmployeeAddPageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/new/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EmployeeAddPageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EmployeeAddPageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EmployeeView />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "service-book",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/employees/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "service-book/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EmployeeServiceBook />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/recruitment",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/recruitment/manage-candidate"} />
            </AuthGuard>
          ),
        },
        {
          path: "add-candidate",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <AddCandidatePage />
            </AuthGuard>
          ),
        },
        {
          path: "manage-candidate",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <ManageCandidate />
            </AuthGuard>
          ),
        },
        {
          path: "candidate-shortlist",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <CandidateShortlist />
            </AuthGuard>
          ),
        },
        {
          path: "interview",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <InterviewPage />
            </AuthGuard>
          ),
        },
        {
          path: "candidate-selection",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <CandidateSelectionPage />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/bank",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/bank/list"} />
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddBankAccountPage />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditBankAccountPage />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <BankListPage />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/award",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/award/list"} />
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddAward />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditAward />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AwardView />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },

        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AwardList />
              </Suspense>{" "}
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/announcement",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/announcement"} />
            </AuthGuard>
          ),
        },
        {
          path: "self",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <MyAnnouncementList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AnnouncementList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AnnouncementView />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/holiday",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/holiday"} />
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <HolidayList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <HolidayView />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/device",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/user"} />
            </AuthGuard>
          ),
        },
        {
          path: "users",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DeviceUserList />
              </Suspense>
            </AuthGuard>
          ),
        },
        // {
        //   path: "view/:id",
        //   element: (
        //     <AuthGuard redirectRoute={"/login/"}>
        //       <Suspense fallback={<LoadingPage />}>
        //         <HolidayView />
        //       </Suspense>
        //     </AuthGuard>
        //   ),
        // },
      ],
    },
    {
      path: "/user",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/user"} />
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <UserPermissionList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <HolidayView />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/transfer",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/transfer/list"} />
            </AuthGuard>
          ),
        },
        {
          path: "self",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <MyTransferList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <TransferList />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/loan",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/loan/report"} />
            </AuthGuard>
          ),
        },
        {
          path: "grant",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <GrantLoanPage />
            </AuthGuard>
          ),
        },
        {
          path: "report",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <LoanReportPage />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/projects",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/projects/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ProjectsManagePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage-po",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <POManagePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage-agreement",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AgreementManagePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ProjectsAddPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-po",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AddPOPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-agreement",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AddAgreementPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/projects/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ProjectsEditPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-po/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <UpdatePOPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-agreement/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <UpdateAgreementPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "details",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/projects/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "po-details/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PODetailsPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "details/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ProjectDetailsPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/department",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/employees/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DepartmentManagePage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DepartmentAddPage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DepartmentEditPage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage-sub",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DesignationManagePage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-sub",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <SubDepartmentAddPage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-sub/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DesignationEditPage />
              </Suspense>
              <Suspense fallback={<LoadingPage />}></Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/reports",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/reports/attendance"} />
            </AuthGuard>
          ),
        },
        {
          path: "attendance",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceReportPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "payroll",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <PayrollListReport />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "settlement",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <SettlementListPageReport />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "leave",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <LeaveReportPageTwo />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "leave-two",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <LeaveReportPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "employee",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EmployeeReportPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        // {path: "project-wise-attendance", element: <AuthGuard redirectRoute={"/login/"}><ProjectWiseAttendance/></AuthGuard>},
        {
          path: "daily-attendance",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DailyAttendanceReport />
              </Suspense>
            </AuthGuard>
          ),
        },

        {
          path: "attendance/daily",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceListReportDaily />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "attendance/summery",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceListReportSummery />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "attendance/add/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceListReportView />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "attendance/view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceListSummeryView />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/settings",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/settings/add-kam"} />
            </AuthGuard>
          ),
        },
        {
          path: "app-config",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AppConfig />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "bank-config",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <BankConfig />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "branch",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <BranchList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-branch",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddBranch />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-branch/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditBranch />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "grade",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <GradeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-grade",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddGrade />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-grade/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditGrade />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "role",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <RoleList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-role",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddRole />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-role/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditRole />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "attendance",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AttendanceConfig />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "award-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AwardTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-award-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddAwardType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-award-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditAwardType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "job-contact",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <JobContactList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-job-contact",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddJobContact />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-job-contact/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditJobContact />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "leave-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <LeaveTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-leave-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddLeaveType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-leave-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditLeaveType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "salary-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <SalaryTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-salary-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddSalaryType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-salary-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditSalaryType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "certificate-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <CertificateTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-certificate-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddCertificateType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-certificate-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditCertificateType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "activity-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ActivityTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-activity-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddActivityType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-activity-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditActivityType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "document-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DocumentTypeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-document-type",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddDocumentType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-document-type/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditDocumentType />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "department-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DepartmentList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-department",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddDepartment />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-department/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditDepartment />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "designation-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <DesignationList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-designation",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddDesignation />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-designation/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditDesignation />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "office-shift-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <OfficeShiftList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-office-shift",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddOfficeShift />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-office-shift/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditOfficeShift />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "office-shift/view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ViewOfficeShift />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "script",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ScriptAdd />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "mail-config",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <MailConfig />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "key-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <KeyList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-key",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AddKey />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-key/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <EditKey />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "role-permissions",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <RolePermission />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "permissions-change",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <PermissionChange />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "permissions-user-change",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <PermissionUserChange />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "user-permissions",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <UserPermission />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "logo-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <LogoList />
              </Suspense>
            </AuthGuard>
          ),
        },

        {
          path: "edit-admin/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditAdminPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-kam",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AddKAMPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-kam/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditKAMPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage-admin",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManageAdminPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage-kam",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManageKAMPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "project-permission",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManagePermission />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "tax-settings",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <TaxSettingsPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "challan-year",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManageChallanYear />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/attendance",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/attendance/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "my/daily",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SelfAttendanceList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "daily",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AttendanceList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "my/summary",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MySummaryList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "summary",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SummaryList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AttendanceAdd />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AttendanceView />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManageAttendancePage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage/bulk",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManageBulkAttendance />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "office-schedule",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <OfficeSchedulePage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/leave",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/leave/weekly-holidays"} />
            </AuthGuard>
          ),
        },
        {
          path: "self",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MyLeaveManage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "weekly-holidays",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <WeeklyHolidayPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add-holidays",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AddLeaveHoliday />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit-holidays/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditLeaveHoliday />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ViewLeave />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "holidays",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <HolidaysPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/activity",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/activity/list"} />
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ActivityList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "add",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AddActivity />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditActivity />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ViewLeave />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "holidays",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <HolidaysPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/payroll",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/payroll/manage"} />
            </AuthGuard>
          ),
        },
        {
          path: "self",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MyPayrollList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ManagePayrolls />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage/view",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PayrollList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PayrollView />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "generate",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PayrollGenerate />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "all-generate",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <AllGenerate />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SingleEditPayrollGenerate />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "invoice",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SalaryChartNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        // {
        //   path: "invoice/:id",
        //   element: (
        //     <AuthGuard redirectRoute={"/login/"}>
        //       <Suspense fallback={<LoadingPage />}>
        //         {" "}
        //         <SalaryChartNew />
        //       </Suspense>
        //     </AuthGuard>
        //   ),
        // },
        {
          path: "generate-bulk",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <GenerateSalaryBulk />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "generate/list/:clientID/:projectID/:month/:sheetId",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <GenerateSalaryEmployeeTable />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/list/:clientID/:projectID/:month/:sheetId",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditGenerateSalaryEmployeeTable />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage/payslip/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PayslipPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "manage/payslip-bangla/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <PayslipBanglaPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "generate/chart/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SalaryChart />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/settlements",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/settlement/list"} />
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SettlementListPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "new",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <NewSettlementPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <EditSettlementPage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SettlementView />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "settlement/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <SettlementPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/message",
      element: <Layout />,
      children: [
        {
          path: "self",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MyMessage />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "inbox",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <Inbox />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "send",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MessageSend />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "compose-list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ComposeList />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "compose",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <MessageCompose />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ViewMessage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/tax-challan",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Navigate to={"/tax-challan/generate"} />
            </AuthGuard>
          ),
        },
        {
          path: "generate",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <GenerateTaxChalanPageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "list",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <TaxChallanListNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "challan-report",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ChallanReport />
              </Suspense>
            </AuthGuard>
          ),
        },
        //{path: "challan-report", element: <AuthGuard redirectRoute={"/login/"}><ChalanReportEditable/></AuthGuard> },
        {
          path: "108",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <Report108PageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "108A",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <Report108APageNew />
              </Suspense>
            </AuthGuard>
          ),
        },
        {
          path: "view/:id",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ChallanView />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/otp-insert",
      element: <OTPInput />,
    },
    {
      path: "/profile",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <ProfilePage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "/account-settings",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard redirectRoute={"/login/"}>
              <Suspense fallback={<LoadingPage />}>
                <AccountSettingsPage />
              </Suspense>
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
};

export default Router;
