//name and label
const selectOptionsIdName = (data = []) => {
  const storeEmployee = [];
  const getEmployeeData = data;
  getEmployeeData?.map((item) => {
    return storeEmployee.push({
      value: item?.id,
      label: item?.name,
    });
  });

  return storeEmployee;
};

export default selectOptionsIdName;

//employee name and label
export const selectOptionsEmployeeId = (data = []) => {
  const storeEmployee = [];
  const getEmployeeData = data;
  getEmployeeData?.map((item) => {
    return storeEmployee.push({
      value: item?.employee_id,
      label: item?.name,
    });
  });

  return storeEmployee;
};

//employee name and label
export const selectOptionsEmailID = (data = []) => {
  const storeEmployee = [];
  const getEmployeeData = data;

  getEmployeeData?.map((item) => {
    if (item?.role_id === 1) {
      return storeEmployee.push({
        value: item?.user_id,
        label: `${item?.email} (${item?.name})`,
        name: item?.name,
        roleID: item?.role_id,
        isDisabled: true,
      });
    } else {
      return storeEmployee.push({
        value: item?.user_id,
        label: `${item?.email} (${item?.name})`,
        name: item?.name,
        roleID: item?.role_id,
      });
    }
  });

  return storeEmployee;
};

//name and label
export const selectOptionsRolePermission = (data = []) => {
  const storeEmployee = [];
  const getEmployeeData = data;
  getEmployeeData?.map((item) => {
    if (item?.id === 1) {
      return storeEmployee.push({
        value: item?.id,
        label: item?.name,
        isDisabled: true,
      });
    } else {
      return storeEmployee.push({
        value: item?.id,
        label: item?.name,
      });
    }
  });

  return storeEmployee;
};
