import { useQuery } from "@tanstack/react-query";
import { getPayFrequencyList } from "../api/fetchers/employeeFetcher";

const usePayFrequencyList = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["payFrequencyList"],
        queryFn: getPayFrequencyList,
    });

    return {
        payFrequencyList: data?.response_data?.map(item => ({ value: item.id, label: item.name })),
        isLoading,
        error
    };
}

export default usePayFrequencyList;