import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

const CircularLoader = ({ size = 30, minHeight = 350 }) => {
    return (
        <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} minHeight={minHeight}>
            <CircularProgress size={size} />
        </Stack>
    );
};

export default CircularLoader;