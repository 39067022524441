import {
  Button,
  ButtonGroup,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAuth } from "../../hooks/useAuth";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string().required("New Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});
const ChangePasswordTab = () => {
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const { user, token } = useAuth();

  const userId = user?.id;

  const onSubmit = async (data) => {
    try {
      const res = await Axios.post(
        `/profile/password/update/${userId}`,
        {
          current_password: data.currentPassword,
          new_password: data.newPassword,
          password_confirmation: data.confirmPassword,
        },
        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (res.data.status === "success") {
        toast.success("Password Change Successful");
        reset();
      } else {
        toast.warn("Something went wrong");
      }
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };
  return (
    <Paper elevation={0} sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          spacing={2}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <Controller
              render={({ field, formState }) => (
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography
                    color={!!formState.errors?.currentPassword ? red[700] : ""}
                  >
                    Current Passowrd
                  </Typography>
                  <OutlinedInput
                    {...field}
                    error={!!formState.errors?.currentPassword}
                  />
                  {!!formState.errors?.currentPassword ? (
                    <FormHelperText error>
                      {errors?.currentPassword?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="currentPassword"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, formState }) => (
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography
                    color={!!formState.errors?.newPassword ? red[700] : ""}
                  >
                    New Password
                  </Typography>
                  <OutlinedInput
                    {...field}
                    error={!!formState.errors?.newPassword}
                  />
                  {!!formState.errors?.newPassword ? (
                    <FormHelperText error>
                      {errors?.newPassword?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="newPassword"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, formState }) => (
                <FormControl fullWidth variant="outlined" size="small">
                  <Typography
                    color={!!formState.errors?.confirmPassword ? red[700] : ""}
                  >
                    Confirm Password
                  </Typography>
                  <OutlinedInput
                    {...field}
                    error={!!formState.errors?.confirmPassword}
                  />
                  {!!formState.errors?.confirmPassword ? (
                    <FormHelperText error>
                      {errors?.confirmPassword?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="confirmPassword"
              control={control}
              defaultValue=""
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "end" }}>
            <ButtonGroup variant="contained">
              <Button type="submit" sx={{ minWidth: "150px !important" }}>
                Change
              </Button>
              <Button color={"inherit"} sx={{ minWidth: "150px !important" }}>
                Cancel
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ChangePasswordTab;
