import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../api/Axios";
import { Helmet } from "react-helmet-async";
import { renderFile } from "../../utils/utils";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const LoginPage = () => {
  //states
  const [showPassword, setShowPassword] = useState(false);
  const [appName, setAppName] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    auth.signin(data.email, data.password);
  };
  useEffect(() => {
    if (auth.token) {
      navigate("/dashboard/home");
    } else {
      const token = localStorage.getItem("token");
      if (token !== null) {
        navigate("/dashboard/home");
      }
    }
  }, [localStorage.getItem("token")]);

  //Get Logo value
  const getConfigData = async () => {
    try {
      const res = await Axios.post(
        "/all/setting",
        {
          name: ["setting_app_name", "company_logo", "company_favIcon"],
        },
        {
          headers: {
            Authorization: auth?.token
              ? "Bearer " + auth?.token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log("getConfigData - res:", res);

      if (res?.data?.status === "success") {
        setAppName(res?.data?.response_data);
      } else if (res?.data?.status === "error") {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let isChecking = true;
    if (isChecking) {
      getConfigData();
    }
    return () => {
      isChecking = false;
    };
  }, []);
  return (
    <Box>
      <Helmet>
        <link
          rel="icon"
          href={
            appName?.company_favIcon
              ? renderFile(appName?.company_favIcon)
              : null
          }
        />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Paper elevation={4} sx={{ bgcolor: blue[200], height: "98vh" }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <img height="400px" src="/assets/images/login/login.svg" alt="" />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "98vh" }}
            >
              {appName?.company_logo ? (
                <Avatar
                  src={renderFile(appName?.company_logo)}
                  alt="logo"
                  variant="square"
                  sx={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "300px",
                    maxHeight: "80px",
                  }}
                />
              ) : (
                <Typography variant="h6" sx={{ fontSize: 16 }}>
                  Please Upload image first
                </Typography>
              )}
              <Box sx={{ maxWidth: 450 }}>
                <Typography sx={{ my: 2 }} variant="h6" textAlign="center">
                  Welcome to {appName?.setting_app_name || ""} Bangladesh.
                </Typography>
                <Typography sx={{ mb: 3 }} variant="body2" textAlign="center">
                  Use your provided email and password to login to your account.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    render={({ field, formState }) => (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="login-email">Email</InputLabel>
                        <OutlinedInput
                          id="login-email"
                          {...field}
                          error={!!formState.errors?.email}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <MdAlternateEmail />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Email"
                        />
                        {!!formState.errors?.email ? (
                          <FormHelperText error>
                            {errors?.email?.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    )}
                    name="email"
                    control={control}
                    defaultValue=""
                  />
                  <Controller
                    render={({ field, formState }) => (
                      <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth>
                        <InputLabel htmlFor="login-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="login-password"
                          type={showPassword ? "text" : "password"}
                          {...field}
                          error={!!formState.errors?.password}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                        {!!formState.errors?.password ? (
                          <FormHelperText error>
                            {errors?.password?.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    )}
                    name="password"
                    control={control}
                    defaultValue=""
                  />
                  {/*<Link to="/dashboard">*/}
                  {/*    <Button variant="text" sx={{mb: 3, color: blue[600]}}>Guest Login</Button>*/}
                  {/*</Link>*/}
                  <Stack alignItems={"start"}>
                    <Button
                      onClick={() => navigate("/forgot-password")}
                      variant={"text"}
                      sx={{ mt: 2 }}
                    >
                      Forgot Password
                    </Button>
                  </Stack>
                  <Button
                    disabled={auth.loading}
                    sx={{ mt: 0 }}
                    size="large"
                    fullWidth
                    variant="contained"
                    type="submit"
                  >
                    Login
                  </Button>
                </form>
              </Box>
            </Stack>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
