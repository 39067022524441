import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../api/Axios";
import { toast } from "react-toastify";

//Validation
const validationSchema = Yup.object().shape({
  emergencyContactPerson: Yup.string().required(
    "Contact Person name is required"
  ),
  emergencyContactRelationship: Yup.string().required(
    "Contact relationship  is required"
  ),
  emergencyContact: Yup.string(),
  altEmergencyContact: Yup.string(),
  emergencyHomePhone: Yup.string(),
  altEmergencyHomePhone: Yup.string(),
  emergencyWorkPhone: Yup.string(),
  altEmergencyWorkPhone: Yup.string(),
  emergencyContactAddress: Yup.string(),
});

function ContactTabNew() {
  const [errorMessage, setErrorMessage] = useState(null);

  //Router dom
  const navigate = useNavigate();
  const { id } = useParams();

  //Context
  const { token } = useAuth();
  const { basicEmployeeInfo, setTabValue, handleShowTabCondition } = useEmployeeContext();

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  //Submit form
  const handleBasicSubmit = async (data) => {
    console.log("handleBasicSubmit - data:", data);
    const postData = {
      name: data.emergencyContactPerson,
      employee_id: id ? id : basicEmployeeInfo?.id,
      relationship: data.emergencyContactRelationship,
      emergency_contact: data.emergencyContact,
      alt_emergency_contact: data.altEmergencyContact,
      home_phone: data.emergencyHomePhone,
      work_phone: data.emergencyWorkPhone,
      alt_home_phone: data.altEmergencyHomePhone,
      alt_work_phone: data.altEmergencyWorkPhone,
      address: data.emergencyContactAddress,
    };

    try {
      const res = await Axios.post(`/employee/contacts`, postData, {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log("employee res:", res);
      if (res?.data?.status === "success") {
        toast.success("Emergency contact info data saved");
        handleShowTabCondition("customField")
        // setTabValue((prev) => prev + 1);
        setErrorMessage(null);
      } else if (res.data.success === "200") {
        toast.info("You don't have contact create  permissions, contact admin");
        navigate("/unauthorized", { replace: true });
      }
    } catch (error) {
      console.log("handleBasicSubmit - error:", error);
      toast.warn("Something went wrong");
      setErrorMessage(error?.response?.data?.error);
    }
  };

  // useEffect(() => {
  //   reset({ ...employeeData });
  // }, []);

  //   useEffect(() => {
  //     console.log("effect Step");
  //     if (stepData !== null) {
  //       console.log("effect Step", stepData);
  //       setValue("emergencyContactPerson", name || "");
  //       setValue("emergencyContactRelationship", relationship || "");
  //       setValue("emergencyContact", emergency_contact || "");
  //       setValue("altEmergencyContact", alt_emergency_contact || "");
  //       setValue("emergencyHomePhone", home_phone || "");
  //       setValue("altEmergencyHomePhone", alt_home_phone || "");
  //       setValue("emergencyWorkPhone", work_phone || "");
  //       setValue("altEmergencyWorkPhone", alt_work_phone || "");
  //       setValue("emergencyContactAddress", address || "");
  //     }
  //   }, [stepData]);

  //Get previous
  const getPreviousInfo = async () => {
    try {
      const response = await Axios.get(
        `employee/contacts/show/${id ? id : basicEmployeeInfo?.id}`,
        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data.response_data);
      if (response.data.response_data) {
        const {
          name,
          relationship,
          emergency_contact,
          alt_emergency_contact,
          home_phone,
          alt_home_phone,
          work_phone,
          alt_work_phone,
          address,
        } = response.data.response_data;
        setValue("emergencyContactPerson", name || "");
        setValue("emergencyContactRelationship", relationship || "");
        setValue("emergencyContact", emergency_contact || "");
        setValue("altEmergencyContact", alt_emergency_contact || "");
        setValue("emergencyHomePhone", home_phone || "");
        setValue("altEmergencyHomePhone", alt_home_phone || "");
        setValue("emergencyWorkPhone", work_phone || "");
        setValue("altEmergencyWorkPhone", alt_work_phone || "");
        setValue("emergencyContactAddress", address || "");
      }
    } catch (error) {
      console.log(error.response.status);
    }
  };

  //get previous contact
  useEffect(() => {
    if (id) {
      getPreviousInfo();
    }
  }, [id]);

  return (
    <form onSubmit={handleSubmit(handleBasicSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactPerson ? red[700] : ""
                  }
                >
                  Emergency Contact Person*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContactPerson}
                />
                {!!formState.errors?.emergencyContactPerson ? (
                  <FormHelperText error>
                    {errors?.emergencyContactPerson?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactPerson"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactRelationship
                      ? red[700]
                      : ""
                  }
                >
                  Contact Relationship*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContactRelationship}
                />
                {!!formState.errors?.emergencyContactRelationship ? (
                  <FormHelperText error>
                    {errors?.emergencyContactRelationship?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactRelationship"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/* <Grid item xs={3}>
        <Controller
          render={({ field, formState }) => (
            <FormControl fullWidth size={"small"} variant="outlined">
              <Typography
                color={
                  !!formState.errors?.emergencyContactnid ? red[700] : ""
                }
              >
                Emergency Contact NID*
              </Typography>
              <OutlinedInput
                {...field}
                error={!!formState.errors?.emergencyContactnid}
              />
              {!!formState.errors?.emergencyContactnid ? (
                <FormHelperText error>
                  {errors?.emergencyContactnid?.message}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          )}
          name="emergencyContactnid"
          control={control}
          defaultValue=""
        />
      </Grid> */}
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyContact ? red[700] : ""}
                >
                  Emergency Contact
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContact}
                />
                {!!formState.errors?.emergencyContact ? (
                  <FormHelperText error>
                    {errors?.emergencyContact?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContact"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyContact ? red[700] : ""
                  }
                >
                  Alt Emergency Contact
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyContact}
                />
                {!!formState.errors?.altEmergencyContact ? (
                  <FormHelperText error>
                    {errors?.altEmergencyContact?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyContact"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyHomePhone ? red[700] : ""}
                >
                  Emergency Home Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyHomePhone}
                />
                {!!formState.errors?.emergencyHomePhone ? (
                  <FormHelperText error>
                    {errors?.emergencyHomePhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyHomePhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyHomePhone ? red[700] : ""
                  }
                >
                  Alt Emergency Home Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyHomePhone}
                />
                {!!formState.errors?.altEmergencyHomePhone ? (
                  <FormHelperText error>
                    {errors?.altEmergencyHomePhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyHomePhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyWorkPhone ? red[700] : ""}
                >
                  Emergency Work Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyWorkPhone}
                />
                {!!formState.errors?.emergencyWorkPhone ? (
                  <FormHelperText error>
                    {errors?.emergencyWorkPhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyWorkPhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyWorkPhone ? red[700] : ""
                  }
                >
                  Alt Emergency Work Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyWorkPhone}
                />
                {!!formState.errors?.altEmergencyWorkPhone ? (
                  <FormHelperText error>
                    {errors?.altEmergencyWorkPhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyWorkPhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactAddress ? red[700] : ""
                  }
                >
                  Emergency Contact Address*
                </Typography>
                <TextField
                  {...field}
                  error={!!formState.errors?.emergencyContactAddress}
                  multiline
                  rows={4}
                />
                {!!formState.errors?.emergencyContactAddress ? (
                  <FormHelperText error>
                    {errors?.emergencyContactAddress?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactAddress"
            control={control}
            defaultValue=""
          />
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={errorMessage} />
      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </form>
  );
}

export default ContactTabNew;
