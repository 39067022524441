import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect } from "react";
import ErrorMessageShow from "./ErrorMessageShow";

const validationSchema = Yup.object().shape({
  emergencyContactPerson: Yup.string().required(
    "Contact Person name is required"
  ),
  emergencyContactRelationship: Yup.string().required(
    "Contact relationship  is required"
  ),
  emergencyContact: Yup.string(),
  altEmergencyContact: Yup.string(),
  emergencyHomePhone: Yup.string(),
  altEmergencyHomePhone: Yup.string(),
  emergencyWorkPhone: Yup.string(),
  altEmergencyWorkPhone: Yup.string(),
  emergencyContactAddress: Yup.string(),
});

const PositionalTab = ({
  onSubmit,
  onPrev,
  employeeData,
  stepData,
  handleError,
}) => {
  console.log("stepData:", stepData);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // useEffect(() => {
  //   reset({ ...employeeData });
  // }, []);

  useEffect(() => {
    console.log("effect Step");
    if (stepData !== null) {
      console.log("effect Step", stepData);
      setValue("emergencyContactPerson", stepData?.name || "");
      setValue("emergencyContactRelationship", stepData?.relationship || "");
      setValue("emergencyContact", stepData?.emergency_contact || "");
      setValue("altEmergencyContact", stepData?.alt_emergency_contact || "");
      setValue("emergencyHomePhone", stepData?.home_phone || "");
      setValue("altEmergencyHomePhone", stepData?.alt_home_phone || "");
      setValue("emergencyWorkPhone", stepData?.work_phone || "");
      setValue("altEmergencyWorkPhone", stepData?.alt_work_phone || "");
      setValue("emergencyContactAddress", stepData?.address || "");
    }
  }, [stepData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactPerson ? red[700] : ""
                  }
                >
                  Emergency Contact Person*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContactPerson}
                />
                {!!formState.errors?.emergencyContactPerson ? (
                  <FormHelperText error>
                    {errors?.emergencyContactPerson?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactPerson"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactRelationship
                      ? red[700]
                      : ""
                  }
                >
                  Emergency Contact Relationship*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContactRelationship}
                />
                {!!formState.errors?.emergencyContactRelationship ? (
                  <FormHelperText error>
                    {errors?.emergencyContactRelationship?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactRelationship"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactnid ? red[700] : ""
                  }
                >
                  Emergency Contact NID*
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContactnid}
                />
                {!!formState.errors?.emergencyContactnid ? (
                  <FormHelperText error>
                    {errors?.emergencyContactnid?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactnid"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyContact ? red[700] : ""}
                >
                  Emergency Contact
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyContact}
                />
                {!!formState.errors?.emergencyContact ? (
                  <FormHelperText error>
                    {errors?.emergencyContact?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContact"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyContact ? red[700] : ""
                  }
                >
                  Alt Emergency Contact
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyContact}
                />
                {!!formState.errors?.altEmergencyContact ? (
                  <FormHelperText error>
                    {errors?.altEmergencyContact?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyContact"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyHomePhone ? red[700] : ""}
                >
                  Emergency Home Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyHomePhone}
                />
                {!!formState.errors?.emergencyHomePhone ? (
                  <FormHelperText error>
                    {errors?.emergencyHomePhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyHomePhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyHomePhone ? red[700] : ""
                  }
                >
                  Alt Emergency Home Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyHomePhone}
                />
                {!!formState.errors?.altEmergencyHomePhone ? (
                  <FormHelperText error>
                    {errors?.altEmergencyHomePhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyHomePhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={!!formState.errors?.emergencyWorkPhone ? red[700] : ""}
                >
                  Emergency Work Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.emergencyWorkPhone}
                />
                {!!formState.errors?.emergencyWorkPhone ? (
                  <FormHelperText error>
                    {errors?.emergencyWorkPhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyWorkPhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.altEmergencyWorkPhone ? red[700] : ""
                  }
                >
                  Alt Emergency Work Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.altEmergencyWorkPhone}
                />
                {!!formState.errors?.altEmergencyWorkPhone ? (
                  <FormHelperText error>
                    {errors?.altEmergencyWorkPhone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="altEmergencyWorkPhone"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"} variant="outlined">
                <Typography
                  color={
                    !!formState.errors?.emergencyContactAddress ? red[700] : ""
                  }
                >
                  Emergency Contact Address*
                </Typography>
                <TextField
                  {...field}
                  error={!!formState.errors?.emergencyContactAddress}
                  multiline
                  rows={4}
                />
                {!!formState.errors?.emergencyContactAddress ? (
                  <FormHelperText error>
                    {errors?.emergencyContactAddress?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="emergencyContactAddress"
            control={control}
            defaultValue=""
          />
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={handleError} />
      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default PositionalTab;
