import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import selectOptionsIdName from "../../../helpers/selectOptions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import Axios from "../../../api/Axios";

//Validation
const validationSchema = Yup.object().shape({
  reference: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email().required("Email is required"),
      phone: Yup.string(),
      relationship: Yup.string().required("Relationship is required"),
      company: Yup.string(),
      designation: Yup.string().required("Designation is required"),
    })
  ),
});

//Data
const relationshipData = [
  { id: 1, label: "Professional", value: "Professional" },
  { id: 2, label: "Relative", value: "Relative" },
  { id: 3, label: "Academic", value: "Academic" },
];

function ReferenceTabNew() {
  const [parentDesignationListData, setParentDesignationListData] = useState([]);

  const [errorMessage, setErrorMessage] = useState(null);

  //Router dom
  const navigate = useNavigate();
  const { id } = useParams();

  //Context
  const { token } = useAuth();
  const { basicEmployeeInfo, setTabValue, handleShowTabCondition } =
    useEmployeeContext();

  //React hook form
  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  // package
  const { fields, append, remove } = useFieldArray({
    control,
    name: "reference",
  });


  useEffect(() => {
    if (!id) {
      append({
        name: "",
        email: "",
        phone: "",
        relationship: "",
        company: "",
        designation: "",
      })
    }
  }, [id, append]);

  //Submit form
  const handleSubmitForm = async (data) => {
    console.log("handleSubmitForm - data:", data, data?.reference);
    try {
      const res = await Axios.post(
        `/employee/reference`,
        {
          employee_id: id ? id : basicEmployeeInfo?.id,
          reference: data?.reference,
        },
        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(res?.data?.employee_id, "employee res:", res);
      if (res?.data?.status === "success") {
        toast.success("Reference info data saved");
        handleShowTabCondition("nominee");
        // setTabValue((prev) => prev + 1);
        setErrorMessage(null);
      } else if (res.data.success === "200") {
        toast.info(
          "You don't have employee create reference info permissions, contact admin"
        );
        navigate("/unauthorized", { replace: true });
      }
    } catch (error) {
      console.log("handleBasicSubmit - error:", error);
      toast.warn("Something went wrong");
      setErrorMessage(error?.response?.data?.error);
    }
  };

  //Get all parent designation
  const getAllParentDesignation = async () => {
    try {
      const res = await Axios.get(
        "/designation/all",

        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (res.data.status === "success") {
        const formatSelect = selectOptionsIdName(res?.data?.response_data);
        setParentDesignationListData(formatSelect);
      } else if (res.data.success === "200") {
        toast.info(
          "You don't have all designation    permissions, contact admin"
        );
        // navigate("/unauthorized", { replace: true });
      } else {
        toast.warn("Something went wrong");
        setParentDesignationListData([]);
      }
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };

  //get position data
  const getReferenceData = async () => {
    try {
      const response = await Axios.get(`/employee/reference/show/${id}`, {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response?.data?.status === "success") {

        if (response?.data?.response_data?.length > 0) {
          for (const item of response?.data?.response_data) {
            append({
              name: item?.name,
              email: item?.email || "",
              phone: item?.phone || "",
              relationship: item?.relationship,
              company: item?.company || "",
              designation: item?.designation | "",
            });
          }
        } else {
          append({
            name: "",
            email: "",
            phone: "",
            relationship: "",
            company: "",
            designation: "",
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.warn("Unable to get reference data");
    }
  };

  //Get all parent designation effect
  useEffect(() => {
    let checkParentDesignation = true;
    if (checkParentDesignation) {
      getAllParentDesignation();
    }
    return () => {
      checkParentDesignation = false;
    };
  }, []);

  useEffect(() => {
    if (id) {
      getReferenceData();
    }
  }, [id]);

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {fields?.length > 0 ? (
            fields?.map((row, index) => {
              return (
                <Grid
                  container
                  spacing={1}
                  alignItems={"start"}
                  sx={{ mb: 1 }}
                  key={index}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      alignItems={"start"}
                      key={index}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontSize: 20 }}>
                          Reference {index + 1}{" "}
                        </Typography>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]?.name
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Reference Name*
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.reference[index]
                                      ?.name
                                    : false
                                }
                                placeholder="Reference Name"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]?.name ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]?.name
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.name`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]
                                      ?.email
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Email*
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.reference[index]
                                      ?.email
                                    : false
                                }
                                placeholder="Enter Email"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]?.email ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]?.email
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.email`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]
                                      ?.phone
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Phone
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.reference[index]
                                      ?.phone
                                    : false
                                }
                                placeholder="Enter Phone"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]?.phone ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]?.phone
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.phone`}
                          control={control}
                          defaultValue={""}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]
                                      ?.relationship
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Relationship
                              </Typography>
                              <ReactSelect
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border:
                                      Object.keys(formState?.errors).length > 0
                                        ? !!formState.errors?.reference[index]
                                          ?.relationship
                                          ? "1px solid #D32F2F"
                                          : "1px solid #C4C4C4"
                                        : "",
                                    "&:hover": {
                                      border:
                                        Object.keys(formState?.errors).length >
                                          0
                                          ? !!formState.errors?.reference[index]
                                            ?.relationship
                                            ? "1px solid #D32F2F"
                                            : "1px solid #C4C4C4"
                                          : "",
                                    },
                                  }),
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                options={relationshipData}
                                getOptionValue={(option) => option.value}
                                placeholder={"Select Relationship"}
                                value={
                                  field?.value !== ""
                                    ? relationshipData?.filter(
                                      (department) =>
                                        department?.value === field?.value
                                    )[0]
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption?.value);
                                }}
                              />
                              {/* <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.reference[index]
                                        ?.phone
                                    : false
                                }
                                placeholder="Limit"
                                size="small"
                              /> */}
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]
                                  ?.relationship ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]
                                      ?.relationship?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.relationship`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]
                                      ?.company
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Company
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.reference[index]
                                      ?.company
                                    : false
                                }
                                placeholder="Enter Company Name"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]?.company ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]?.company
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.company`}
                          control={control}
                          defaultValue={""}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.reference[index]
                                      ?.designation
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Designation*
                              </Typography>
                              <ReactSelect
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border:
                                      Object.keys(formState?.errors).length > 0
                                        ? !!formState.errors?.reference[index]
                                          ?.designation
                                          ? "1px solid #D32F2F"
                                          : "1px solid #C4C4C4"
                                        : "",
                                    "&:hover": {
                                      border:
                                        Object.keys(formState?.errors).length >
                                          0
                                          ? !!formState.errors?.reference[index]
                                            ?.designation
                                            ? "1px solid #D32F2F"
                                            : "1px solid #C4C4C4"
                                          : "",
                                    },
                                  }),
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                options={parentDesignationListData}
                                getOptionValue={(option) => option.value}
                                placeholder={"Select Relationship"}
                                value={
                                  field?.value !== ""
                                    ? parentDesignationListData?.filter(
                                      (department) =>
                                        department?.value === field?.value
                                    )[0]
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption?.value);
                                }}
                              />

                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.reference[index]
                                  ?.designation ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.reference[index]
                                      ?.designation?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`reference.${index}.designation`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {fields?.length > 1 ? (
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => remove(index)}
                        sx={{ mt: 2 }}
                      >
                        remove this reference
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })
          ) : (
            <Typography variant="formTitle" align="center" width={1} mt={3}>
              No list create yet{" "}
            </Typography>
          )}
          <Box sx={{ mt: 4 }}>
            <Button variant="outlined" onClick={() => append()}>
              Add another reference
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={errorMessage} />

      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ReferenceTabNew;
