import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  Button,
  Divider,
  Stack,
  Avatar,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineCamera, AiOutlineClose, AiOutlineCloudUpload } from "react-icons/ai";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import selectOptionsIdName from "../../../helpers/selectOptions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import Axios from "../../../api/Axios";
import { useDropzone } from "react-dropzone";
import { renderFile } from "../../../utils/utils";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const MAX_FILE_SIZE = 50007200; //500KB

//Validation
const validationSchema = Yup.object().shape({
  nominee: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      fatherName: Yup.string(),
      motherName: Yup.string(),
      dob: Yup.string().required("Date is required"),
      gender: Yup.string().required("Gender is required"),
      image: Yup.mixed(),
    })
  ),
});

//Data
const genderData = [
  { id: 1, label: "Male", value: "Male" },
  { id: 2, label: "Female", value: "Female" },
  { id: 3, label: "Others", value: "Others" },
];

function NomineeTabNew() {
  const [parentDesignationListData, setParentDesignationListData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  //Router dom
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("BasicInfoTabNew - id:", id);

  //Context
  const { token } = useAuth();
  const { basicEmployeeInfo, setTabValue, handleShowTabCondition } =
    useEmployeeContext();

  //React hook form
  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // package
  const { fields, append, remove } = useFieldArray({
    control,
    name: "nominee",
  });
  const [nomineeImages, setNomineeImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);


  useEffect(() => {
    if (!id) {
      append({
        name: "",
        fatherName: "",
        motherName: "",
        dob: "",
        gender: "",
        image: "",
      })
    }
  }, [id, append]);

  //Submit form
  const handleSubmitForm = async (data) => {
    console.log("handleSubmitForm - data:", data);

    const formData = new FormData();

    data?.nominee?.forEach((item, index) => {
      formData.append(
        `nominee[${index}][name]`,
        item.name === null ? "" : item.name
      );
      formData.append(
        `nominee[${index}][gender]`,
        item.gender === null ? "" : item.gender
      );
      formData.append(
        `nominee[${index}][father_name]`,
        item.fatherName === null ? "" : item.fatherName
      );
      formData.append(
        `nominee[${index}][mother_name]`,
        item.motherName === null ? "" : item.motherName
      );
      formData.append(
        `nominee[${index}][dob]`,
        item.dob === null ? "" : item.dob
      );
      formData.append(
        `nominee[${index}][image]`,
        item.image === null ? "" : item.image
      );
    });
    formData.append(
      "employee_id",
      id ? id : basicEmployeeInfo?.id ? basicEmployeeInfo?.id : ""
    );

    try {
      const res = await Axios.post(`/employee/nominee`, formData, {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res?.data?.employee_id, "employee res:", res);
      if (res?.data?.status === "success") {
        toast.success("Nominee info data saved");
        handleShowTabCondition("contact")
        // setTabValue((prev) => prev + 1);
        setErrorMessage(null);
      } else if (res.data.success === "200") {
        toast.info(
          "You don't have employee create positional info permissions, contact admin"
        );
        navigate("/unauthorized", { replace: true });
      }
    } catch (error) {
      console.log("handleBasicSubmit - error:", error);
      toast.warn("Something went wrong");
      setErrorMessage(error?.response?.data?.error);
    }
  };

  //Get all parent designation
  const getAllParentDesignation = async () => {
    try {
      const res = await Axios.get(
        "/designation/all",

        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (res.data.status === "success") {
        const formatSelect = selectOptionsIdName(res?.data?.response_data);
        setParentDesignationListData(formatSelect);
      } else if (res.data.success === "200") {
        toast.info(
          "You don't have all designation    permissions, contact admin"
        );
        // navigate("/unauthorized", { replace: true });
      } else {
        toast.warn("Something went wrong");
        setParentDesignationListData([]);
      }
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };

  //get position data
  const getNomineeData = async () => {
    try {
      const response = await Axios.get(`/employee/nominee/show/${id}`, {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response?.data?.status === "success") {
        // reset({ nominee: [] });
        // const formatRefData = [];

        if (response?.data?.response_data?.length > 0) {
          for (const item of response?.data?.response_data) {
            const nominee = {
              name: item.name,
              gender: item.gender,
              fatherName: item.father_name,
              motherName: item.mother_name,
              dob: item.dob,
              image: item.image,
            };
            append(nominee);

            setNomineeImages((prev) => ([...prev, item?.image]))
          }
        } else {
          const nominee = {
            name: "",
            gender: "",
            fatherName: "",
            motherName: "",
            dob: "",
            image: "",
          };
          append(nominee);
        }

      }
    } catch (error) {
      console.log(error);
      toast.warn("Unable to get nominee data");
    }
  };

  //Get all parent designation effect
  useEffect(() => {
    let checkParentDesignation = true;
    if (checkParentDesignation) {
      getAllParentDesignation();
    }
    return () => {
      checkParentDesignation = false;
    };
  }, []);

  useEffect(() => {
    if (id) {
      getNomineeData();
    }
  }, [id]);

  const handlePreviewImage = (file, index) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setPreviewImages((prev) => {
        const updatedImages = [...prev];
        const existingImageIndex = updatedImages.findIndex((image) => image.index === index);

        if (existingImageIndex !== -1) {
          updatedImages[existingImageIndex] = { index: index, file: file, src: event.target.result };
        } else {
          updatedImages.push({ index: index, file: file, src: event.target.result });
        }

        return updatedImages;
      });
    };
    reader.readAsDataURL(file);
  };


  console.log("Prev Image: ", previewImages)


  return (
    <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {fields?.length > 0 ? (
            fields?.map((row, index) => {
              return (
                <Grid
                  container
                  spacing={1}
                  alignItems={"start"}
                  sx={{ mb: 1 }}
                  key={index}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      alignItems={"start"}
                      key={index}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontSize: 20 }}>
                          Nominee {index + 1}{" "}
                        </Typography>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]?.image
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Upload Nominee Photo
                              </Typography>

                              {/* Preview area  */}
                              {previewImages?.find(item => item?.index === index) ? (
                                <Avatar
                                  variant="square"
                                  src={previewImages?.find(item => item?.index === index)?.src}
                                  sx={{
                                    width: "auto",
                                    height: "auto",
                                    maxWidth: 200,
                                    maxHeight: 150,
                                    objectFit: "cover",
                                    mt: 1,
                                    borderRadius: 1
                                  }}
                                />
                              ) :
                                nomineeImages[index] ?
                                  (
                                    <Avatar
                                      variant="square"
                                      src={renderFile(nomineeImages[index])}
                                      sx={{
                                        width: "auto",
                                        height: "auto",
                                        maxWidth: 200,
                                        maxHeight: 150,
                                        objectFit: "cover",
                                        mt: 1,
                                        borderRadius: 1
                                      }}
                                    />
                                  ) : null}


                              {/* Upload Button  */}
                              <Button variant="contained"
                                component="label"
                                startIcon={<AiOutlineCloudUpload />}
                                sx={{ mt: 1, mb: 2 }} >
                                <TextField
                                  // {...field}
                                  error={
                                    Object.keys(formState?.errors).length > 0
                                      ? !!formState?.errors?.nominee[index]?.image
                                      : false
                                  }
                                  inputProps={{
                                    accept:
                                      "image/jpg ,image/jpeg, image/png, image/gif ,image/svg ,image/webp",
                                  }}
                                  placeholder="Reference Name"
                                  size="small"
                                  type="file"
                                  onChange={(file) => {
                                    handlePreviewImage(file?.target?.files[0], index);
                                    field.onChange(file?.target?.files[0]);
                                  }}
                                  sx={{
                                    clip: 'rect(0 0 0 0)',
                                    clipPath: 'inset(50%)',
                                    height: 1,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    whiteSpace: 'nowrap',
                                    width: 1,
                                  }}
                                />
                                Upload File
                              </Button>

                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]?.image ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]?.image
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.image`}
                          control={control}
                          defaultValue=""
                        />

                      </Grid>

                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" >
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]?.name
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Nominee Name*
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.nominee[index]?.name
                                    : false
                                }
                                placeholder="Nominee Name"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]?.name ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]?.name
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.name`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]
                                      ?.fatherName
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Father Name
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.nominee[index]
                                      ?.fatherName
                                    : false
                                }
                                placeholder="Enter Father Name"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]
                                  ?.fatherName ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]
                                      ?.fatherName?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.fatherName`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]
                                      ?.motherName
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Mother Name
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.nominee[index]
                                      ?.motherName
                                    : false
                                }
                                placeholder="Enter Mother Name"
                                size="small"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]
                                  ?.motherName ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]
                                      ?.motherName?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.motherName`}
                          control={control}
                          defaultValue={""}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]
                                      ?.relationship
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                Gender*
                              </Typography>
                              <ReactSelect
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    border:
                                      Object.keys(formState?.errors).length > 0
                                        ? !!formState.errors?.nominee[index]
                                          ?.gender
                                          ? "1px solid #D32F2F"
                                          : "1px solid #C4C4C4"
                                        : "",
                                    "&:hover": {
                                      border:
                                        Object.keys(formState?.errors).length >
                                          0
                                          ? !!formState.errors?.nominee[index]
                                            ?.gender
                                            ? "1px solid #D32F2F"
                                            : "1px solid #C4C4C4"
                                          : "",
                                    },
                                  }),
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                options={genderData}
                                getOptionValue={(option) => option.value}
                                placeholder={"Select Gender"}
                                value={
                                  field?.value !== ""
                                    ? genderData?.filter(
                                      (department) =>
                                        department?.value === field?.value
                                    )[0]
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption?.value);
                                }}
                              />
                              {/* <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.nominee[index]
                                        ?.phone
                                    : false
                                }
                                placeholder="Limit"
                                size="small"
                              /> */}
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]?.gender ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]?.gender
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.gender`}
                          control={control}
                          defaultValue=""
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Controller
                          render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                color={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState.errors?.nominee[index]?.dob
                                      ? red[700]
                                      : ""
                                    : ""
                                }
                              >
                                DOB*
                              </Typography>
                              <TextField
                                {...field}
                                error={
                                  Object.keys(formState?.errors).length > 0
                                    ? !!formState?.errors?.nominee[index]?.dob
                                    : false
                                }
                                placeholder="Enter Date of Birth"
                                size="small"
                                type="date"
                              />
                              {Object.keys(formState?.errors).length > 0 &&
                                !!formState?.errors?.nominee[index]?.dob ? (
                                <FormHelperText error>
                                  {
                                    formState?.errors?.nominee[index]?.dob
                                      ?.message
                                  }
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          name={`nominee.${index}.dob`}
                          control={control}
                          defaultValue={""}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {fields?.length > 1 ? (
                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => remove(index)}
                        sx={{ mt: 2 }}
                      >
                        remove this nominee
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })
          ) : (
            <Typography variant="formTitle" align="center" width={1} mt={3}>
              No list create yet{" "}
            </Typography>
          )}
          <Box sx={{ mt: 4 }}>
            <Button variant="outlined" onClick={() => append()}>
              Add another nominee
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={errorMessage} />

      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default NomineeTabNew;
