import React, { useEffect, useState } from "react";
import { useAuth } from "../useAuth";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ROLENAME } from "../../helpers/commonUses";

function GetUserPermission() {
  const [storePermissionPath, setStorePermissionPath] = useState([]);
  // console.log("render");

  //Context
  const { role, token, user } = useAuth();
  const getUserInfo = JSON.parse(localStorage.getItem("user"));
  const roleName = ROLENAME;
  const location = useLocation();
  //Get Previous User Data
  const getPreviousUserPermissionList = async () => {
    try {
      const res = await Axios.get(
        "/get/self/permission",

        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log("getPreviousUserPermissionList - res:", res);

      if (res.data.status === "success") {
        setStorePermissionPath(res?.data?.response_data?.route_path);
        // setShouldFetchData((prev)=>!prev)
      } else {
        // toast.warn("Something went wrong");
        setStorePermissionPath([]);
      }
    } catch (error) {
      console.log("branch list setStoreSelectedPermission", error);

      // setStorePermissionPath([]);
      if (error?.response?.status !== 422) {
        toast.warn("Something went wrong on permission ");
      }
    }
  };

  useEffect(() => {
    if (role !== roleName) {
      getPreviousUserPermissionList();
    }
  }, []);
  return { storePermissionPath };
}

export default GetUserPermission;
